.text-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 73vh;
}

.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: rgb(216, 215, 215);
}

.letter {
  margin: 0 2px;
  animation: wave 1.5s infinite;
  animation-timing-function: linear;
}

@keyframes wave {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.letter:nth-child(1) {
  animation-delay: 0s;
}

.letter:nth-child(2) {
  animation-delay: 0.1s;
}

.letter:nth-child(3) {
  animation-delay: 0.2s;
}

.letter:nth-child(4) {
  animation-delay: 0.3s;
}

.letter:nth-child(5) {
  animation-delay: 0.4s;
}

.letter:nth-child(6) {
  animation-delay: 0.5s;
}

.letter:nth-child(7) {
  animation-delay: 0.6s;
}
