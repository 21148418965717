@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600&family=Monoton&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-color: #ede4d4;
  --primary-text-color: #624343;
  --secondary-text-color: #807c77;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* For Firefox Browser */
  scrollbar-width: thin;
  scrollbar-color: currentColor #fff;
}

/* For Chrome, EDGE, Opera, Others */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
}

body {
  background-color: var(--theme-color);
  font-family: "Josefin Sans", sans-serif;
}
.font-monoton {
  font-family: "Monoton", cursive;
}
